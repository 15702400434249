import Vue from "vue";
import VueRouter from "vue-router";
import CleatLayout from "@/layouts/CleatLayout";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    beforeEnter: (to, from, next) => {
      next({ name: "Home" });
    },
  },
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "HomeView" */ "../views/HomeView.vue"),
  },
  {
    path: "/courses",
    component: CleatLayout,
    children: [
      {
        path: "",
        name: "Courses",
        component: () =>
          import(
            /* webpackChunkName: "CoursesView" */ "../views/CoursesView.vue"
          ),
      },
      {
        path: ":id",
        name: "Course",
        component: () =>
          import(
            /* webpackChunkName: "CourseView" */ "../views/CourseView.vue"
          ),
      },
    ],
  },
  {
    path: "/docs",
    component: CleatLayout,
    children: [
      {
        path: "",
        beforeEnter: (to, from, next) => {
          next({ name: "Home" });
        },
      },
      {
        path: "contacts",
        name: "Contacts",
        component: () =>
          import(
            /* webpackChunkName: "ContactsView" */ "../views/ContactsView.vue"
          ),
      },
      {
        path: "rules",
        name: "Rules",
        component: () =>
          import(/* webpackChunkName: "RulesView" */ "../views/RulesView.vue"),
      },
      {
        path: "policy",
        name: "Policy",
        component: () =>
          import(
            /* webpackChunkName: "PolicyView" */ "../views/PolicyView.vue"
          ),
      },
      {
        path: "payment-secure",
        name: "PaymentSecure",
        component: () =>
          import(
            /* webpackChunkName: "PaymentSecure" */ "../views/PaymentSecure.vue"
          ),
      },
    ],
  },

  {
    path: "/order/:id",
    component: () =>
      import(/* webpackChunkName: "PaymentPage" */ "../views/PaymentPage.vue"),
    meta: {
      hideInfo: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
